<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.app-main {
  width: 100%;
  z-index: 1;
}
.fixed-header+.app-main {
  padding-top: 100px;
}
</style>

<style>
.el-popup-parent--hidden .fixed-header{
  padding-right: 15px;
}
</style>
