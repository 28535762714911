import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import '@/styles/base.css'
import store from '@/store/index.js'

import router from './router'

Vue.config.productionTip = false

window.EventBus = new Vue();

Vue.prototype.$store = store

Vue.use(ElementUI);

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
