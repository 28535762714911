<template>
<div>
	<div class="isPc">
		  <div class="flex flex-center dx" style="padding: 10px 40px;background-color: #fff;
		  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)">
		  	<div class="flex1">
		  		<a href="/">
		  			<img :src="website.logo" style="width: 220px;height: 72px;"
		  			class="pointer">
		  		</a>
		  	</div> 
		  	
		  	<el-menu :default-active="activeIndex" class="" mode="horizontal"
		  	active-text-color="#d80c24" @select="handleSelect">
		  		<template v-for="item in menuList">
		  			<el-menu-item :index="item.index" v-if="!item.children" 
		  			:key="item.index">{{item.title}}</el-menu-item>
		  			<el-submenu v-else :index="item.index" :key="item.index">
		  				<template slot="title">{{item.title}}</template>
		  				<el-menu-item v-for="it in item.children" :key="it.index"
		  				:index="it.index">{{it.title}}</el-menu-item>
		  			</el-submenu>
		  		</template>
		  	</el-menu>
		  </div>
	</div>
	<div class="isMobile">
		<div class="flex flex-center plr10 ptb10 bg-white">
			<div>
				<el-popover
				  placement="top"
				  width="300"
				  v-model="visible">
				  
				  <el-menu :default-active="activeIndex" mode="vertical"
				  active-text-color="#d80c24" @select="handleSelect">
				  	<template v-for="item in menuList">
				  		<el-menu-item :index="item.index" v-if="!item.children" 
				  		:key="item.index">{{item.title}}</el-menu-item>
				  		<el-submenu v-else :index="item.index" :key="item.index">
				  			<template slot="title">{{item.title}}</template>
				  			<el-menu-item v-for="it in item.children" :key="it.index"
				  			:index="it.index">{{it.title}}</el-menu-item>
				  		</el-submenu>
				  	</template>
				  </el-menu>
				  
				  <div slot="reference">
					  <i class="el-icon-s-fold text-gray" style="font-size: 30px;"></i>
				  </div>
				</el-popover>
			</div>
			<div class="flex1 ac">
				<a href="/">
					<img :src="website.logo" style="width: 100px;height: 42px;"
					class="pointer">
				</a>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'dxHeader',
  data(){
	  return{
		  activeIndex:'0',
		  visible:false,
		  menuList:[
			  {title:'首页',index:'0',path:'/'},
			//   {title:'产品中心',index:'1',path:'/productCenter'},
			  {title:'产品中心',index:'1',path:'productCenter',children:[
			      {title:'消防探测器',index:'1-0',path:'/productCenter/FireDetector'},
				  {title:'智能家居',index:'1-1',path:'/productCenter/SmartHome'},
				  {title:'智慧消防',index:'1-2',path:'/productCenter/SmartFireProtection'},
				  {title:'家用电器',index:'1-3',path:'/productCenter/HouseholdAppliance'},
			  ]},
			  {title:'新闻中心',index:'2',path:'/newsCenter'},
			  {title:'解决方案',index:'3',path:'/solutionCenter'},
			  {title:'关于公司',index:'4',path:'about',children:[
				  {title:'公司简介',index:'4-0',path:'/about/companyProfile'},
				  {title:'工厂设备',index:'4-1',path:'/about/plantEquipment'},
				  {title:'荣誉证书',index:'4-2',path:'/about/certificateOfHonor'},
				  {title:'常见问答',index:'4-3',path:'/about/fAQ'},
			  ]},
			  {title:'联系我们',index:'5',path:'/contactUs'},
		  ]
	  }
  },
  computed:{
	...mapState(['website'])
  },
  mounted() {
  	let hash = location.hash;
	this.getActiveIndex(hash);
	this.changeIndex();
  },
  methods:{
	changeIndex(){
		EventBus.$off('navbar-changeIndex')
		EventBus.$on('navbar-changeIndex', (pathname,needPush=true) => {
			this.getActiveIndex(pathname);
			if(needPush){
				this.$router.push(pathname)
			}
		})
	},
	getActiveIndex(data){//回显菜单active样式
		// let arr = pathname.split('#');
		let pathname = '';
		if(data.startsWith('#')){
			pathname = data.split('#')[1];
		}else{
			pathname = data;
		}
		
		let arr = pathname.split('/');
		let item = {};
		
		if(arr.length == 2){
			// console.log(pathname,'===')
			item = this.menuList.find(it=>it.path == pathname)||{};
			if(pathname=='/dashboard'){
				item = this.menuList.find(it=>it.path == '/');
			}
		}else if(arr.length == 3){
			let fatherName = arr[1];
			
			// let father = this.menuList.find(it=>it.path == fatherName)
			// item = father.children.find(it=>it.path == pathname)
			let father = this.menuList.find(it=>{
				if(it.path.endsWith(fatherName)){
					return it
				}
			})
			// console.log(father,fatherName,'===')
			if(fatherName=='productCenter'){
				item = {index:'1-0'}
			}else if(father.children){
				item = father.children.find(it=>it.path == pathname)
			}else{
				item = father
			}
			
			// console.log(item,'item')
		}
		
		this.activeIndex = item.index||'0';
	},
	handleSelect(e,arr){
		let item = {}
		if(arr.length==1){
			item = this.menuList.find(it=>it.index == arr[0]);
		}else if(arr.length>1){
			let father = this.menuList.find(it=>it.index == arr[0]);
			item = father.children.find(it=>it.index == arr[1])
		}
		if(item.path){
			this.activeIndex = item.index;
			this.$router.push(item.path);
			this.visible = false;
		}
	}
  }
}
</script>

<style scoped>
.dx .el-menu.el-menu--horizontal{
	border-bottom: none !important;
}
.dx .el-menu--horizontal>.el-menu-item{
	color: #000;
	font-weight: bold;
}

</style>
<style>
.dx .el-menu--horizontal>.el-submenu .el-submenu__title{
	color: #000 ;
	font-weight: bold;
}

.el-menu--horizontal .el-menu .el-menu-item{
	color: #000 !important;
}
</style>
