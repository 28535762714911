import Vue from 'vue'
import Vuex from 'vuex'
import {getCompanyInfo} from '@/api/web.js'

Vue.use(Vuex)

const store = new Vuex.Store({ //全局变量定义
	state: {
		company:{},
        website:{},
	},
	mutations: {
		
	},
	actions: {
		getCompanyInfo({state}){
            getCompanyInfo().then(res=>{
                if(res.code == 200){
                  state.company = res.data.company||{};
                  state.website = res.data.website||{};
				  
				  document.title = res.data.website.seoTitle||'宁波铠盾电子工业股份有限公司';
                }
              })
		},
	}

})

export default store