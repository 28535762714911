<template>
<div>
	<div class="isPc">
		<div class="flex f14 text-999" style="padding-left: 150px;padding-right: 150px;margin-top: 150px;">
			<div class="flex1">
				<div class="clear">
					<div class="w20">
						<div class="b mb20 text-black f16">快速链接</div>
						<div class="mb20 pointer" @click="toPage('/productCenter')">产品中心</div>
						<div class="mb20 pointer" @click="toPage('/newsCenter')">新闻中心</div>
						<div class="mb20 pointer" @click="toPage('/solutionCenter')">解决方案</div>
						<div class="mb20 pointer" @click="toPage('/about/companyProfile')">关于公司</div>
						<div class="mb20 pointer" @click="toPage('/contactUs')">联系我们</div>
					</div>
					<div class="w30">
						<div class="b mb20 text-black f16">联系我们</div>
						<div class="mb20">地址: {{company.address}}</div>
						<div class="mb20">座机: {{company.phone}}</div>
						<div class="mb20">传真: {{company.fax}}</div>
						<div class="mb20">邮箱: {{company.email}}</div>
					</div>
				</div>
			</div>
			<div>
				<div class="ac inline-block" style="">
					<img src="@/assets/footer/qr-1.png" style="width: 180px;height: 180px;">
					<div>家之安公众号</div>
				</div>
				<div class="ac inline-block" style="margin-left: 100px;">
					<img src="@/assets/footer/qr-2.png" style="width: 180px;height: 180px;">
					<div>家之安小程序</div>
				</div>
			</div>
		</div>
	</div>
	<div class="isMobile plr20 pt20">
		<div class="b mb10 text-black">联系我们</div>
		<div class="mb10 text-a6adb6 f14"><i class="el-icon-location-outline mr5"></i>地址: {{company.address}}</div>
		<div class="mb10 text-a6adb6 f14"><i class="el-icon-phone-outline mr5"></i>座机: {{company.phone}}</div>
		<div class="mb10 text-a6adb6 f14"><i class="el-icon-printer mr5"></i>传真: {{company.fax}}</div>
		<div class="mb10 text-a6adb6 f14"><i class="el-icon-folder mr5"></i>邮箱: {{company.email}}</div>
		<div class="flex flex-center f14">
			<div class="ac flex1">
				<img src="@/assets/footer/qr-1.png" style="width: 100px;height: 100px;">
				<div>家之安公众号</div>
			</div>
			<div class="ac flex1">
				<img src="@/assets/footer/qr-2.png" style="width: 100px;height: 100px;">
				<div>家之安小程序</div>
			</div>
		</div>
	</div>
	<el-divider></el-divider>
	<div class="ac text-999 f14 pb20 isPc">
		<span class="vm">版权所有:</span>
		<span class="ml5 vm">{{website.copyright}}</span>
		
		<span class="pointer vm ml20" @click="toBeiAnPage">{{website.icp}}</span>
		
		<img src="../icon.png" style="width: 16px;height: 16px;" class="vm mlr10"
		v-if="website.mps">
		<span class="pointer vm" @click="toBeiAnPageTwo">{{website.mps}}</span>
	</div>
	<div class="ac text-999 f14 pb20 isMobile">
		<div class="mb5">
			<span class="vm">版权所有:</span>
			<span class="ml5 vm">{{website.copyright}}</span>
		</div>
		<div>
			<span class="pointer" @click="toBeiAnPage">{{website.icp}}</span>
		</div>
	</div>
</div>	
</template>

<script>
import {mapState} from 'vuex'
export default{
	data(){
		return{
			
		}
	},
	computed:{
	...mapState(['website','company'])
  },
	methods:{
		toPage(url){
			EventBus.$emit('navbar-changeIndex',url)
		},
		toBeiAnPage(){
		  window.open(this.website.icpLink)
		},
		toBeiAnPageTwo(){
		  window.open(this.website.mpsLink)
		},
	}
}	
</script>

<style>
.text-999{color: #999;}
.text-a6adb6{
	color: #a6adb6;
}
</style>