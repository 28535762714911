<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		computed: {

		},
		mounted() {
			if (window.history && window.history.pushState) {
				//监听浏览器 返回上一页下一页事件
				window.addEventListener('popstate', this.goBack, false);
			}
			this.initWOW()
		},
		beforeDestroy() {
			window.removeEventListener('popstate', this.goBack, false);
		},
		methods: {
			goBack() {
				let hash = location.hash;
				EventBus.$emit('navbar-changeIndex', hash,false)
			},
			initWOW(){
				const wow = new WOW({
				    boxClass: "wow",// 对所有拥有该class的元素挂载动画
				    animateClass: "animated",// 需要挂载的动画元素
				    offset: 100,// 偏移值（即偏移一定距离后再执行动画，推荐设置！）
				    mobile: true,// 移动设备是否生效
				    live: true,// 是否支持异步
				  });
				wow.init();
			}
		}
	}
</script>

<style>
	body {
		font-family:'Source Han Sans', sans-serif;
	}
	.dx-detailPage table{
		border-collapse:collapse;
	}
	.dx-detailPage table, .dx-detailPage th, .dx-detailPage td{
		border: 1px solid #ccc;
		padding:10px;
	}
	
	* {
		margin: 0;
		padding: 0
	}

	.dx-detailPage li {
		margin-left: 40px;
	}

	img {
		vertical-align: top;
		border: none
	}

	.plr150 {
		padding-left: 150px;
		padding-right: 150px;
	}

	.dx-title {
		font-size: 24px;
		font-weight: bold;
		position: relative;
		display: inline-block;
	}

	.dx-title::before {
		display: inline-block;
		content: "";
		width: 50%;
		height: 4px;
		background-color: #d80c24;
		position: absolute;
		bottom: -10px;
		left: 0;
	}

	.dx-title::after {
		display: inline-block;
		content: "";
		width: 10%;
		height: 4px;
		background-color: #dddddd;
		position: absolute;
		bottom: -10px;
		left: 60%;
	}
	
	.hover-img img{
		transition: transform 1s ease;
	}
	.hover-img:hover img{
		transform: scale(1.2)
	}

	/* 联系我们 按钮 */
	.dx-btn {
		position: relative;
		width: 100px;
		height: 60px;
		text-align: center;
		line-height: 60px;
		text-decoration: none;
		color: #fff;
		padding: 0 20px;
		text-transform: uppercase;
		transition: 0.5s;
		overflow: hidden;
		-webkit-box-reflect: below 1px linear-gradient(transparent, #1113);
	}

	.dx-btn::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		border-top: 2px solid #fff;
		border-left: 2px solid #fff;
		transition: 0.5s;
		transition-delay: 0.5s;
	}

	.dx-btn::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 20px;
		height: 20px;
		border-bottom: 2px solid #fff;
		border-right: 2px solid #fff;
		transition: 0.5s;
		transition-delay: 0.5s;
	}

	.dx-btn:hover::before,
	.dx-btn:hover::after {
		width: 100%;
		height: 100%;
		transition-delay: 0s;
	}

	.dx-btn:hover {
		background-color: #fff;
		color: #000;
		box-shadow: 0 0 50px #fff;
		transition-delay: 0.3s;
	}

	/* 查看更多 按钮 */
	.dx .learn-more-btn {
		color: #d80c24;
		cursor: pointer;
		display: block;
		position: relative;
		border: 2px solid #d80c24;
		transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
		padding: 10px 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.dx:hover .learn-more-btn {
		color: #fff !important;
		background-color: transparent;
		text-shadow: nthree;
	}

	.dx:hover .learn-more-btn:before {
		left: 0%;
		right: auto;
		width: 100%;
	}

	.dx .learn-more-btn:before {
		display: block;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 0px;
		z-index: -1;
		content: '';
		background: #d80c24;
		transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
	}
	.dx-shadow{
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
	}
	
	.sidebar-info {
		padding: 30px;
		background: url(@/assets/footer/people.jpg) center center;
		background-size: cover;
		position: relative;
		z-index: 1;
		text-align: center;
		color: #fff;
	}
	
	.sidebar-info::after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #d80c24;
		z-index: -1;
		opacity: .83;
	}
	
	/* 滚动条样式 */
	body::-webkit-scrollbar {
	  width: 4px;
	  height: 4px;
	}
	body::-webkit-scrollbar-thumb {
	  background-color: #d80c24;
	  border-radius: 4px;
	}
	
	
	/* @media screen and (min-width: 480px) {
		.isMobile{
			display: none;
		}
	} */
	.isMobile{
		display: none;
	}
	@media screen and (max-width: 800px) {
		.isMobile{
			display: block;
		}
	}
	
	.isPc{
		display: none;
	}
	@media screen and (min-width: 800px) {
		.isPc{
			display: block;
		}
	}
</style>