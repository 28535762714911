import Vue from 'vue'
import Router from 'vue-router'

 
// hack router push callback
// [解决 vue-router跳转相同路径报错 ]
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: { title: '首页' }
    }]
  },
  {
    path: '/about',
    component: Layout,
    redirect: '/about/companyProfile',
    children: [
		{
		   path: '/about/companyProfile',
		   name: 'CompanyProfile',
		   component: () => import('@/views/about/companyProfile.vue'),
		   meta: { title: '公司简介' }
        },
		{
		   path: '/about/plantEquipment',
		   name: 'PlantEquipment',
		   component: () => import('@/views/about/plantEquipment.vue'),
		   meta: { title: '工厂设备' }
		},
		{
		   path: '/about/certificateOfHonor',
		   name: 'CertificateOfHonor',
		   component: () => import('@/views/about/certificateOfHonor.vue'),
		   meta: { title: '荣誉证书' }
		},
		{
		   path: '/about/fAQ',
		   name: 'FAQ',
		   component: () => import('@/views/about/fAQ.vue'),
		   meta: { title: '常见问答' }
		},
	]
  },
  {
    path: '/productCenter',
    component: Layout,
    redirect: '/productCenter',
    children: [{
      path: '/productCenter',
      name: 'ProductCenter',
      component: () => import('@/views/productCenter/index'),
      meta: { title: '产品中心' }
    },{
      path: '/productCenter/detail',
      name: 'ProductCenterDetail',
      component: () => import('@/views/productCenter/detail'),
      meta: { title: '产品详细' }
    },{
      path: '/productCenter/FireDetector',
      component: () => import('@/views/productCenter/index'),
      meta: { title: '消防探测器' }
    },{
      path: '/productCenter/SmartHome',
      component: () => import('@/views/productCenter/index'),
      meta: { title: '智能家居' }
    },{
      path: '/productCenter/SmartFireProtection',
      component: () => import('@/views/productCenter/index'),
      meta: { title: '智慧消防' }
    },{
      path: '/productCenter/HouseholdAppliance',
      component: () => import('@/views/productCenter/index'),
      meta: { title: '家用电器' }
    }]
  },
  {
    path: '/newsCenter',
    component: Layout,
    redirect: '/newsCenter',
    children: [{
      path: '/newsCenter',
      name: 'NewsCenter',
      component: () => import('@/views/newsCenter/index'),
      meta: { title: '新闻中心' }
    },{
      path: '/newsCenter/detail',
      name: 'NewsCenterDetail',
      component: () => import('@/views/newsCenter/detail'),
      meta: { title: '新闻详细' }
    }]
  },
  {
    path: '/solutionCenter',
    component: Layout,
    redirect: '/solutionCenter',
    children: [{
      path: '/solutionCenter',
      name: 'SolutionCenter',
      component: () => import('@/views/solutionCenter/index'),
      meta: { title: '解决方案' }
    },{
      path: '/solutionCenter/detail',
      name: 'SolutionCenterDetail',
      component: () => import('@/views/solutionCenter/detail'),
      meta: { title: '方案详细' }
    }]
  },
  {
    path: '/contactUs',
    component: Layout,
    redirect: '/contactUs',
    children: [{
      path: '/contactUs',
      name: 'ContactUs',
      component: () => import('@/views/contactUs/index'),
      meta: { title: '联系我们' }
    }]
  },
  {
    path: '/errorPage',
    component: () => import('@/views/error/errorPage'),
    hidden: true
  },
  { path: '*', redirect: '/errorPage', hidden: true }
]

const createRouter = () => new Router({
  mode: 'hash', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
