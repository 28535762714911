import request from '@/libs/request'

export function addMessage(data) {
    return request({
      url: '/inquiry/add',
      data,
      method:'post',
    })
 }

export function getUserDicts(params) {
  return request({
    url: '/wuser/getUserDicts',
    params,
    method:'get',
  })
}

 export function getCompanyInfo(params) {
  return request({
    url: '/owe/company/detail/kingdun/owe',
    params,
    method:'get',
  })
}

export function getBanners(params) {
  return request({
    url: '/owe/banner/list',
    params,
    method:'get',
  })
}

export function getContent(params) {
  return request({
    url: '/owe/info/list',
    params,
    method:'get',
  })
}

export function getProduct(params) {
  return request({
    url: '/owe/prod/list',
    params,
    method:'get',
  })
}
export function getProductDetail(id) {
  return request({
    url: '/owe/prod/detail/'+id,
    method:'get',
  })
}

export function getNewsDetail(id) {
  return request({
    url: '/owe/info/detail/'+id,
    method:'get',
  })
}