<template>
  <div class="app-wrapper">
    <div class="clear">
      <div class="fixed-header">
        <Navbar></Navbar>
      </div>
      <AppMain></AppMain>
	  <div>
		  <Footer></Footer>
	  </div>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import AppMain from './components/AppMain.vue'
import Footer from './components/Footer.vue'

import {getUserDicts} from '@/api/web.js'
export default {
  name: 'Layout',
  components: {
    Navbar,
    AppMain,
	  Footer,
  },
  data(){
    return{
      
    }
  },
  mounted(){
    this.$store.dispatch('getCompanyInfo');
    this.getAllUserDicts()
  },
  methods: {
    getAllUserDicts(){
      getUserDicts().then(res=>{
        if(res.code == 200){
          localStorage.setItem('allUserDicts',JSON.stringify(res.data))
        }
      })
    }
  }
}
</script>
<style scoped>
.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
  }	
.fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
  }	
</style>
